.footer {
    color: white;
    bottom: 0;
    z-index: 4;
    position: relative;
    min-height: 10vh;
    flex:1,
}

.text-highlight {

    padding: 0.5rem 1rem;
    margin: 0.5rem;
    border-radius:3px; /* Optional: adds rounded corners */


}
    
.letterFooter {
    display: inline-block;
    font-family: 'coolvetica';
    font-size: 6rem; /* Larger font size for visibility */

    color: #000;
}

.text-sections {
    display: flex;
    flex-direction: column;
    gap: 1rem; /* Space between sections */
}

.text-sections h2, .text-sections p {
    margin: 0; /* Removes default margin */
}

img {
    vertical-align: middle; /* Aligns images with the text */
}

/* Ensures the footer content is not too tightly packed */
.container {
    max-width: 1280px;
    padding: 0rem 0rem;
}

/* More space around the footer text */
.footer p {
    padding: 0rem 0;
}

@media (max-width: 768px) {
    .letterFooter {
    
        font-size: 3rem; /* Larger font size for visibility */
    

    }
}

@media (max-width: 480px) {
    .letterFooter {
        display: inline-block;
    
        font-size: 5.1rem;
    

    }
}