/* PosterPage.css */
@tailwind base;
@tailwind components;
@tailwind utilities;


.content-background{
width: 100%;
min-height: 100vh;
background-color: #f9f6ec;
padding: 10px;

}



.page-background {

   width:100%;
   min-height: 100%;
   display: flex;
   flex-direction: column;
   justify-content: flex-start; /* Adjusted to align content to the start */
   align-items: center; /* Ensure items are centered horizontally */
   position: relative;
 }
 



.piano-container {
  width: 100%;
  padding-top: 60px; /* Adjust this value to match your header's height */
  position: relative;
}



.project-selector {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}


.project-button {
  padding: 10px 20px;
  margin: 0 10px;
  border-radius: 10px;
  color: #9b9b9b; /* Text color */
  background-color: #493636; /* Button background color */
  border: 2px solid #b44040; /* Initial border color set to red */
  cursor: pointer;
  transition: all 0.3s ease; /* Smooth transition for visual changes */
}

.project-button:hover {
  background-color: #4c3d30; /* Maintains the background color */
  border-color: #dbae27; /* Changes border color to amber */
}

.project-button.active, .project-button:active {
  background-color: #3c4f3d; /* Maintains the background color */
  border-color: #2dbc7e; /* Changes border color to green */
}



.interaction-button {
  padding: 10px 20px;
  margin-top: 10px;
  background-color: #4CAF50;
  color: white;
  border: none;
  cursor: pointer;
}

@media (max-width: 768px) {
  .project-content {
      flex-direction: column;
  }

  .project-info {
      order: -1; 
  }
}


/* P5Page.css - Adjusted styles for better desktop layout */

.p5-projects-container {
  padding: 20px;
  margin: auto;
  background-color: #f9f6ec;
  max-width: 100%; /* Ensures that the container does not stretch too wide */
  display: flex;
  flex-direction: column;
  align-items: center; /* Center aligns the children */
}

.project-content {
  display: flex;
  flex-wrap: wrap; /* Allows children to wrap on smaller screens */
  justify-content: center; /* Centers the children horizontally */

  padding:10px;
  width: 100%; /* Ensures the content uses up the available width */
}

.project-canvas {
  flex: 1; /* Takes 3 parts of the space if available */
  text-align: center;
  min-height: 400px; /* Minimum height to ensure visibility */
  padding: 10px;
  margin: 20px;
  flex-direction: row;
justify-content: center;
align-items: center;
  border: 1px solid rgb(0, 0, 0);
  color: rgb(167, 167, 167);
}

.mosaic-project-canvas {
  width: 100%; /* Takes the full width of its parent */
  height: auto; /* Height should be auto to maintain aspect ratio */
  display: flex;
  justify-content: center;
  align-items: center;
  background: #333; /* Optional: Sets a background color */
}


.project-info {
  flex: 1; /* Takes 1 part of the space */
  max-width: 40%; /* Limits the width to 40% of the parent container */
  padding: 10px;
  background: #f9f9f9;
  border: 1px solid #ccc;
}




/* Position the text over the Piano */
.piano-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none; /* This is crucial to allow clicks to pass through to the piano */
}

.piano-overlay h1 {
  pointer-events: none; /* Ensure this is also non-interactive */
  color: rgb(255, 255, 255); /* Assuming a dark piano, white text will stand out */
  font-size: 3.8rem;
  text-shadow: 2px 2px 8px rgba(0,0,0,0.8); /* Optional: Adds a shadow for better readability */
}

/* Styles for the date input field */
.date-input {
  background: #2a2a2a; /* Dark grey background */
  border: 2px solid #565656; /* Slightly lighter grey border */
  color: white; /* White text */
  padding: 8px 12px; /* Padding for better touch interaction */
  border-radius: 8px; /* Rounded corners */
  font-size: 16px; /* Larger font for better readability */
  margin-right: 20px;
}

.date-input:focus {
  border-color: #009688; /* Teal border on focus for visibility */
  outline: none; /* Removing default outline */
}


/* THE BELOW SECTION IS FOR IMAGE MOSAIC*/

/* Styles for the start processing button */
.start-button {
  background: #2a2a2a; /* Dark grey background */
  border: 2px solid #565656; /* Slightly lighter grey border */
  color: white; /* White text */
  padding: 8px 12px; /* Padding for better touch interaction */
  border-radius: 8px; /* Rounded corners */
  font-size: 16px; /* Larger font for better readability */
  cursor: pointer; /* Changes the cursor to a pointer to indicate it's clickable */
  transition: all 0.3s ease; /* Smooth transition for hover and focus effects */
}

.start-button:hover {
  border-color: #009688; /* Teal border on hover for visibility */
}

.start-button:focus, .start-button:active {
  outline: none; /* Removing default outline */
  background-color: #009688; /* Teal background when active/focused */
}

.start-button:active {
  box-shadow: 0 0 8px #009688; /* Green glow effect when clicked */
}

.p5-button-container{
  flex-direction: column;
}




@media (max-width: 767px) {
  .project-canvas {
      max-width: 100%; /* Allows the canvas to take full width on smaller screens */
  }
  .project-info {
      max-width: 100%; /* Allows the info section to take full width on smaller screens */
  }

  .piano-overlay h1 {



  font-size: 1.8rem;

}

.project-button {
  padding: 3px 6px;
  margin: 0 2px;

}

.project-selector {
  flex-direction: column;

}

}
