.circleNavContainer {
  position: absolute;
  width: 70vh; /* Half of the original width, if necessary */
  height: 70vh;
margin-left: 35vw;
  bottom: -85vh;
z-index: 1;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  pointer-events: auto;

}


.backgroundImage, .rotatingCircle {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.circle {
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;  /* Ensure this is intended for non-interactive layers */

  overflow: hidden;
}

.rotatingCircle {
  pointer-events: auto; /* Only the rotating circle should be interactive */
  cursor: pointer; /* Show pointer cursor on hover */
  overflow: hidden;
  z-index: 10; /* Ensures this is above other non-interactive elements */
}


.backgroundImage {
  pointer-events: none; /* Only the rotating circle should be interactive */
  overflow: hidden;
}

.toggleButton{
  pointer-events: auto; /* Only the rotating circle should be interactive */
  position: relative;
  top:100;
  right:200;
  overflow: hidden;
}