/* Define the font-face */
@font-face {
  font-family: 'HabbyFont'; /* A name for your custom font */
  src: url("../../../../public/Inter-Regular.ttf") format('truetype'); /* Adjust the path and format */

  font-weight: normal;
  font-style: normal;
}

/* Define the font-face */
@font-face {
  font-family: 'HabbyFontSB'; /* A name for your custom font */
  src: url("../../../../public/Inter-SemiBold.ttf") format('truetype'); /* Adjust the path and format */

  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: 'coolvetica';
  src: 
       url('../../../../public/coolveticaa.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}


/* Common base for floating animations */
@keyframes float {
  
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-10px); }
}


@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.spin-animation {
  animation: spin 1s linear infinite;
}



/* Base styles for the header */
.header {
  opacity: 0;
  transition: opacity 0.5s ease;
  background-color: var(--background-color);
  color: var(--text-color);
  display: flex;
  justify-content: flex-start;
  padding: 10px 20px;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 10;
}

.header.visible {
  opacity: 1;
}

/* Button styling */
.header-button {
  background-color: var(--button-background-color);
  color: var(--text-color);
  border: 1px solid var(--border-color);
  border-radius: 5px;
  padding: 10px 20px;
  margin-left: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.header-button:hover {
  background-color: var(--button-hover-background-color);
}

/* Floating header and its responsiveness */
.floating-header {
  display: flex;
  justify-content: space-evenly;
  align-items: flex-end;
  width: 100%;
  height: 15vw;
  overflow: visible;
  background-color: var(--background-color); /* Adjust if needed */
}

.floating-header img {
  width: 8vw; /* Base width */
  height: auto;
  margin: 2 3vw;
  object-fit: contain;
}


.sky-background-container {
  transition: background 0.5s ease-out;
  width: 100%;
  position: relative;
  /* Other styles as needed */
}

.sky-background-prev, .sky-background-current {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity 0.5s ease-out; /* Smooth transition */
}

.sky-background-prev {
  z-index: 1;
}

.sky-background-current {
  z-index: 2;
  opacity: 0;
}

/* Assuming .sky-background-current becomes fully opaque when skyBackground changes */
 .sky-background-current {
  opacity: 1;
}


.cloud-container {
  overflow: hidden;
  width: 100%;
  height: 25vh; /* Adjust this height as needed */
  position: relative; /* Context for absolute positioning */
}


.clouds, .slow-clouds {
  display: flex;
  width: 200%; /* 2x the width for scrolling */
  position: absolute; /* Positioned within cloud-container */
  left: 0;
  bottom: 0;
}


.clouds {
  z-index: 2; /* Ensures this layer is above the slower clouds */
  animation: scrollClouds 40s linear infinite;
}

.slow-clouds {
  z-index: 1; /* Behind the faster clouds */
  animation: scrollClouds 60s linear infinite; /* Slower animation */
}

.cloud-image {
  min-width: 50%; /* Ensures image covers half the width of its parent */
  height: 100%; /* Fill the container height */
  object-fit: cover; /* Maintains aspect ratio */
}

@keyframes scrollClouds {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-50%);
  }
}


/* Responsive adjustments */
@media (max-width: 768px) {
  .floating-header img { width: 8vw; }
  .cloud-container {height: 10vh;}
  .floating-header {
 
    height: 25vw;

  }
}

@media (max-width: 480px) {
  .floating-header img { width: 10vw; }
  .cloud-container {height: 10vh;}
}

