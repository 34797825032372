/* In src/components/header/NavBar/NavBar.css */
.navbar {
    display: flex;
    justify-content: space-evenly;
    background-color: rgb(90, 163, 208);
    height: 20%;
    z-index: 1000; /* High z-index to ensure it's above the Header */
    position: relative; /* z-index works on positioned elements (position:absolute, position:relative, or position:fixed) */
  }
  
  .nav-button {
    flex-grow: 1;
    background-color: rgb(100, 178, 223);
    border-right:  rgb(65, 124, 160);
    border: none;
    color: rgb(202, 227, 242);
    padding: 10px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 45px;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
  }
  
  .nav-button:hover {
    background-color: rgb(75, 148, 191); /* A tiny shade darker on hover */
  }

  @media (max-width: 600px) {
    .nav-button {
        flex-direction: column; /* Stacks buttons vertically on mobile */
        font-size: 20px;
    }
  
    .navbar {
      display: none;
    
    }
  
  }
  