/* HomePage.css */
.page-background {
  background-color: #f9f6ec; /* Light grey background for the page */
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.hero-section {
  width: 100%;
  height: 50vh;
  background-color: #f9f6ec;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0px;
}

.hero-text-section{
  background-color: #f9f6ec;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 20px;
  
}

.hero-image {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
}

.hero-text {
  font-size: 20px;
  color: #333333;
  width: 80%;
  margin-top: 20px;
}



.color-button {
  flex: 1 1 100%; /* Takes up a third of the space, flex-grow, flex-shrink, flex-basis */
  height: 40vh; /* 20% of the screen's height */
  width: 30%;
  margin: 10px; /* Margin around buttons */
  padding: 10px 20px; /* Padding inside the button */
  cursor: pointer; /* Cursor changes to pointer on hover */

  border-radius: 5px; /* Rounded corners for the button */
  transition: background-color 0.3s; /* Smooth transition for hover effect */
  color: white; /* White text color */
  border-width: 1px;
  border-color: #333333;
  font-family: 'coolvetica';
  font-size: 4rem;
}


.color-button-red { background-color: #cf3429; } /* Red button */
.color-button-yellow { background-color: #e9d841; } /* Yellow button */
.color-button-blue { background-color: #3068a5; } /* Blue button */

.color-button:hover {
  opacity: 0.8; /* Slightly darker shade on hover for feedback */
}

.navigation-buttons{
  width:100%;
  margin: 20px;
}

/* Responsive font sizes using CSS */
.hero-text-section h1 {
  font-size: 12rem; /* Responsive font size */
}

.sunButton{ 
  position:absolute;
    right: 0;
     height: 180px;
      width: 180px ;
      
       }

@media (max-width: 768px) {

  .sunButton{ 
    position:relative;
    height: 80px;
    right: -40%;
    width: 80px ;
         }

  .hero-section {
  
    flex-direction: column;
    padding: 0px;

  }

  .pageAndButton{
    flex-direction: column;
  }
  


  .hero-text-section{
    padding: 0px;
  }


  .hero-text-section h1 {
    font-size: 5rem; /* Fixed size on larger screens */
  }

  .color-button {

    font-size: 2rem;
  }
}


@media (max-width: 600px) {
  .navigation-buttons {
      flex-direction: column; /* Stacks buttons vertically on mobile */
      font-size: 1rem; /* Fixed size on larger screens */
  }

  .color-button {
      width: 80%; /* Makes button take more width for easier click */
      margin: 10px auto; /* Centers the button horizontally with margin */
  }
}
