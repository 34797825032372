/* PosterPage.css */
@tailwind base;
@tailwind components;
@tailwind utilities;



.page-background {
   flex: 1;
   display: flex;
   flex-direction: column;
   justify-content: flex-start; /* Adjusted to align content to the start */
   align-items: center; /* Ensure items are centered horizontally */
 }
 
 .matrix-rain-banner {
   width: 100%; /* Full width */
   height: 30vh; /* Adjust based on desired banner height */
   margin-top: 20px; /* Optional: adds some space at the top */
 }
 
 /* PosterPage.css */
.shop-page-background {
   height: 100vh;
   width: 100%;
   background: linear-gradient(135deg, #0f0c29 0%, #302b63 50%, #d4d4dc 100%);
   color: white; /* Ensuring text is visible against the dark background */
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: start;
 }
 
 .sale-badge {
   position: absolute;
   top: 0;
   right: 0;
   background-color: #ff0000;
   color: #ffffff;
   padding: 0.5em;
   z-index: 10;
   font-size: 1em;
   font-weight: bold;
 }
 
 .original-price {
   text-decoration: line-through;
   color: #777;
   margin-right: 0.5rem;
 }
 
 /* Add to PosterPage.css */
.countdown-text {
   font-size: 1rem; /* Large text size for visibility */

   color: #FFF; /* White text color */


   display: inline-block; /* Ensures the background only wraps text */
   margin-bottom: 20px; /* Space above the header title */
 }
 
 .countdown {
   font-weight: bold; /* Bold for the countdown numbers */
 }
 
/* Styling for each poster card */
/* Styling for each poster card */
.poster-card {
   transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out, background-color 0.3s ease-in-out;
   background-color: rgba(255, 255, 255, 0.5); /* Semi-transparent white */
   cursor: pointer; /* Indicates the card is interactive */
 }
 
 /* Hover effect */
 .poster-card:hover {
   transform: scale(1.05); /* Enlarges the card by 5% */
   box-shadow: 0 4px 20px rgba(0,0,0,0.25); /* Adds depth with shadow */
   background-color: #ffffff; /* Changes background to fully opaque white */
 }
 
 
 /* Ensuring the cards do not disturb other elements when scaled */
 .poster-card:hover ~ .poster-card {
   transition: transform 0.3s ease-in-out;
 }
 
 
 .poster-card:hover h2, 
.poster-card:hover p {
  color: #333; /* Change text color to dark gray for better visibility on white background */
}