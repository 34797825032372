/* Base styles */
.p5-letter {
  display: inline-block;
  margin: 0 0px; /* Adjust spacing as needed */
  transition: all 0.5s ease;
  font-size: 1.9em; /* Further adjust font size for very small screens */
  padding: 0%;
  font-family: 'coolvetica';
}

.p5-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #212121;
  border-bottom: 1px solid black;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 100;
  transition: all 0.5s ease;
  opacity: 1;
  
}

.p5-menu {
  display: flex;
  flex:1;
  justify-content: space-around;
  align-items: center;
}

.p5-menu-item {
  display: inline-block;
  flex:1;
  padding:10px;
  margin: 5px;
  color: #918c8c;
  background-color: #212121;;
}

.p5-menu-item:hover {
  background-color: #4f4d4d; /* Slightly darker shade on hover for feedback */
}

/* Hide burger menu on larger screens by default */
.p5-burger-menu {
  color: #f9f6ec;
  display: none;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .p5-header {
    justify-content: space-between; /* Align burger menu and title */
    flex-direction: row-reverse;
    color: #f9f6ec;

  }
  
  .p5-burger-menu{
    display: block; /* Show burger menu on small screens */
    cursor: pointer;
    color: #f9f6ec;
  }
  
  /* Hide regular menu items, show them when menu is toggled open */
  .p5-menu, .header-button {
    display: none;
  }
  
  .p5-menu.open {
    display: flex; /* Change to block for vertical layout */
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 50px; /* Adjust based on header size */
    left: 0;
    width: 100%;
    background-color: #212121; /* Match header bg color */
  }
  
  .p5-menu-item {
    display: block;
    width: 100%;
    padding: 10px; /* Adjust padding for touch targets */
  }
  
  .title .p5-letter {
    margin: 3px 5px;
    font-size: 1.5em;
  }
}

@media (max-width: 480px) {
  .title .p5-letter {
    font-size: 1.2em; /* Further adjust font size for very small screens */
  }
}

.divider {
  height: 20px; /* Adjust height as necessary */
  width: 1px;
  background-color: #ccc; /* Divider color */
  display: inline-block; /* Align with inline items */
  margin: 0 8px; /* Space around the divider */
}

