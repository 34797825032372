
  /* Scrolled state for title */

  
  .homeHeader {
    opacity: 0;
    transition: opacity 0.5s ease;
    background-color: #f9f6ec; /* Changed to white */

    display: flex;
    justify-content: flex-start; /* Align items to the left */
    padding: 10px 20px;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1;
  }
  
  .homeHeader.visible {
    opacity: 1;
  }
  
  .homeHeader-button {
    background-color: #f9f6ec; /* Button background */
    color: #dddad0; /* Button text color */

border-width: 0px;
    cursor: pointer; /* Cursor changes to pointer on hover */
    transition: background-color 0.3s ease; /* Smooth transition for hover effect */
  }
  
  .homeHeader-button:hover {
    background-color: #f0f0f0; /* Slightly darker shade on hover for feedback */
  }
  